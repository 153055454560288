import { React, useState } from "react";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import { Accordion, Form } from "react-bootstrap";

const AdminLoginSettings = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="appointments loginsettings">
          <h4 class="h4">Admin Login Settings</h4>

          <div className="row">
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-8 col-xxxxl-6">
              <div className="custom_accor">
                <Accordion>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Notification Preferences
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="content">Return and Refunds</p>
                        <div className="custom_toggle">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <p className="content">Lorem ipsum</p>
                        <div className="custom_toggle">
                          <label class="switch">
                            <input type="checkbox" checked />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Change Password</Accordion.Header>
                    <Accordion.Body>
                      <div className="row justify-content-center">
                        <div className="col-10 col-md-9 col-lg-8 col-xl-6">
                          <Form>
                            <Form.Group className="mb-3">
                              <Form.Label>Old Password</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Demo"
                              ></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>New Password</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Demo"
                              ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Confirm Password</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Demo"
                              ></Form.Control>
                            </Form.Group>
                          </Form>
                          <div className="d-flex flex-wrap  align-items-center gap-3 mt_2rem">
                            <button className="btn btn-details">Cancel</button>
                            <button className="btn btn-details bgdarkgreen">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Enable Two-step Verification
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla
                      </p>
                      <div className="mt-3">
                        <Form.Check
                          type="checkbox"
                          label="Allow user to turn on 2-step Verification"
                          className="d-flex align-items-center custom_chckbox"
                        ></Form.Check>

                        <div className="mt-4 ps-5 ms-4">
                          <p className="text_green_md">Enforcement</p>
                          <div className="custom_radio_button">
                            <label class="container">
                              <input type="radio" name="radio" />
                              <span class="checkmark"></span>
                              Off
                            </label>
                            <label class="container">
                              <input type="radio" name="radio" checked />
                              <span class="checkmark"></span>
                              on
                            </label>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginSettings;
