import { React, useState } from "react";
import { Link } from "react-router-dom";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import { BsPlusCircle } from "react-icons/bs";
import adminimg from "../Assets/Images/dashboard/men.png";

// local file import

import noprofile from "../Assets/Images/notprofile.png";

const Provider = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="provider">
          <div className="appointments">
            <h4 className="h4">Provider</h4>
            <div className="formbox">
              <div className="providerhead">
                <Link className="btn btn-details bgdarkgreen" to="/add-admin">
                  <span className="pe-1">
                    <BsPlusCircle />
                  </span>{" "}
                  Add Admin
                </Link>
              </div>
              <div className="providerbody">
                <div className="profileimg">
                  {/* <img src={adminimg} alt="Profile" /> */}
                  <img src={noprofile} alt="Profile" />
                </div>
                <span className="profilename">Lorem ipsum</span>
                <div className="overview">
                  <div className="overviewhead">
                    <p className="overviewtop">Overview</p>
                  </div>
                  <div className="overviewbody">
                    <p className="content mb-3">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolorum harum odio pariatur sit autem sapiente! Maxime
                      optio doloremque iusto excepturi rerum, corrupti facere,
                      odio neque ea omnis et, in ex. Lorem ipsum dolor sit amet
                      consectetur adipisicing elit. Dolorum harum odio pariatur
                      sit autem sapiente! Maxime optio doloremque iusto
                      excepturi rerum, corrupti facere, odio neque ea omnis et,
                      in ex.
                    </p>
                    <p className="content">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Excepturi possimus aliquam ducimus quisquam nobis ipsa
                      aliquid laboriosam facere atque. Atque at incidunt ab
                      necessitatibus natus tenetur consequatur esse quos
                      corporis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Provider;
