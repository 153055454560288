import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import PatientTable from "./table/PatientTable";

const DashCalendar = () => {
  const navigate = useNavigate();
  const handleTotalPatientNavigate = () => {
    navigate("/superadmin-total-patients");
  };
  const handleNewAppointmentNavigate = () => {
    navigate("/superadmin-new-appointments");
  };
  const handleAdminProviderAccessNavigate = () => {
    navigate("/superAdmin-providerAccess");
  };
  const handleEarningNavigate = () => {
    navigate("/earnings");
  };
  return (
    <>
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />
          <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
            {/* <DashHome /> */}
          </div>
          <div className="superadmindashboard">
            <div className="appointments">
              <Row>
                <Col lg={3} md={4} sm={6} className="mb-4">
                  <div
                    className="dash__singleBox d-flex flex-column justify-content-between overflow-hidden rounded-4"
                    style={{ cursor: "pointer" }}
                    onClick={handleTotalPatientNavigate}
                  >
                    <div className="d-flex justify-content-between align-items-start px-3 pt-4">
                      <img
                        src={
                          require("../Assets/Images/dashboard/dashImage/totalpatients.svg")
                            .default
                        }
                        className="img-fluid dash__singleBoxLogos rounded-3"
                      />
                      <div className="dash__singleBoxCountHolder">
                        <p className="m-0 dash__singleBoxLabel text-end">
                          Total Patients
                        </p>
                        <h6 className="m-0 dash__singleBoxValue text-end mt-1">
                          0
                        </h6>
                      </div>
                    </div>
                    <img
                      src={
                        require("../Assets/Images/dashboard/dashImage/bluegraph.svg")
                          .default
                      }
                      className="img-fluid  dash__singleBoxGraph mt-3"
                    />
                  </div>
                </Col>

                <Col lg={3} md={4} sm={6} className="mb-4">
                  <div
                    className="dash__singleBox d-flex flex-column justify-content-between overflow-hidden rounded-4 greenline"
                    style={{ cursor: "pointer" }}
                    onClick={handleNewAppointmentNavigate}
                  >
                    <div className="d-flex justify-content-between align-items-start px-3 pt-4">
                      <img
                        src={
                          require("../Assets/Images/dashboard/dashImage/calendar.svg")
                            .default
                        }
                        className="img-fluid dash__singleBoxLogos rounded-3"
                      />
                      <div className="dash__singleBoxCountHolder">
                        <p className="m-0 dash__singleBoxLabel text-end">
                          New Appointment
                        </p>
                        <h6 className="m-0 dash__singleBoxValue text-end mt-1">
                          0
                        </h6>
                      </div>
                    </div>
                    <img
                      src={
                        require("../Assets/Images/dashboard/dashImage/greenGraph.svg")
                          .default
                      }
                      className="img-fluid  dash__singleBoxGraph mt-3"
                    />
                  </div>
                </Col>

                <Col lg={3} md={4} sm={6} className="mb-4">
                  <div
                    className="dash__singleBox d-flex flex-column justify-content-between overflow-hidden rounded-4 orangeline"
                    style={{ cursor: "pointer" }}
                    onClick={handleAdminProviderAccessNavigate}
                  >
                    <div className="d-flex justify-content-between align-items-start px-3 pt-4">
                      <img
                        src={
                          require("../Assets/Images/dashboard/dashImage/doctor.svg")
                            .default
                        }
                        className="img-fluid dash__singleBoxLogos rounded-3"
                      />
                      <div className="dash__singleBoxCountHolder">
                        <p className="m-0 dash__singleBoxLabel text-end">
                          Total Doctor
                        </p>
                        <h6 className="m-0 dash__singleBoxValue text-end mt-1">
                          0
                        </h6>
                      </div>
                    </div>
                    <img
                      src={
                        require("../Assets/Images/dashboard/dashImage/orangegraph.svg")
                          .default
                      }
                      className="img-fluid  dash__singleBoxGraph mt-3"
                    />
                  </div>
                </Col>

                <Col lg={3} md={4} sm={6} className="mb-4">
                  <div
                    className="dash__singleBox d-flex flex-column justify-content-between overflow-hidden rounded-4 violetline"
                    style={{ cursor: "pointer" }}
                    onClick={handleEarningNavigate}
                  >
                    <div className="d-flex justify-content-between align-items-start px-3 pt-4">
                      <img
                        src={
                          require("../Assets/Images/dashboard/dashImage/earnings.svg")
                            .default
                        }
                        className="img-fluid dash__singleBoxLogos rounded-3"
                      />
                      <div className="dash__singleBoxCountHolder">
                        <p className="m-0 dash__singleBoxLabel text-end">
                          Earnings
                        </p>
                        <h6 className="m-0 dash__singleBoxValue text-end mt-1">
                          0
                        </h6>
                      </div>
                    </div>
                    <img
                      src={
                        require("../Assets/Images/dashboard/dashImage/vioGraph.svg")
                          .default
                      }
                      className="img-fluid  dash__singleBoxGraph mt-3"
                    />
                  </div>
                </Col>
              </Row>

              <PatientTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashCalendar;
