import { React } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const DeleteNewAppointment = (props) => {
  const { show, handleClose } = props;
  return (
    <Modal
      show={show}
      onHide={props.close}
      className="modalbox deleteticket"
      centered
    >
      <Modal.Body>
        <p className="content booking">Are You Delete this Appoinment ?</p>
        <p className="content mt-2 mb-4">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy
        </p>
        <button
          className="btn btn-details bgdarkgreen me-3"
          onClick={handleClose}
        >
          Confirm
        </button>
        <button className="btn btn-details" onClick={handleClose}>
          Cancel
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteNewAppointment;
