import React, { useState } from "react";

// icon import

import { FaEyeSlash, FaEye } from "react-icons/fa";

// Files import

import logo from "../Assets/Images/black-logo.png";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const handleSwitchPassword = () => {
    setPasswordShow(!passwordShow);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <div className="admin_creds">
      <div className="container h-100">
        <div className="row justify-content-center align-items-center h-100 ">
          <div className="col-5">
            <div className="auth_cards">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="logo" className="img-fluid" />
              </div>

              <h4 className="auth_title pt-5">Forgot Password</h4>

              <div className="d-flex flex-column mt-4">
                <label className="ps-0 auth_label">Email Id</label>
                <input
                  type="text"
                  className="auth_input mt-2 px-2"
                  placeholder="Email Address"
                />
                {/* <p className="auth_error_text mt-2 mb-0">*Email need</p> */}
              </div>

              <div className="mt_2rem">
                <button className="pm_button_three w-100" onClick={handleLogin}>
                  Forgot
                </button>
              </div>

              <div className="mt-3 d-flex justify-content-center ">
                <button className="line_button" onClick={handleLogin}>
                  Go to login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
