import { React, useState } from "react";
import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import { Table } from "react-bootstrap";
import doctorimg from "../../Assets/Images/dashboard/doctor-three.png";
import { FaEye } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import DeleteTicketsModal from "../Modal/DeleteTicketsModal";

const SupportAddressTable = () => {
  const tableheaddata = [
    "S.No",
    "Id",
    "subjects",
    "assignee",
    "status",
    "created date",
    "due date",
    "action",
  ];
  const [tabledata, setTabledata] = useState([
    {
      sno: "1",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "open",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
    {
      sno: "2",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "close",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
    {
      sno: "3",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "open",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
    {
      sno: "4",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "close",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
    {
      sno: "5",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "open",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
    {
      sno: "6",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "open",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
    {
      sno: "7",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "close",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
    {
      sno: "8",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "open",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
    {
      sno: "9",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "close",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
    {
      sno: "10",
      id: "102153",
      subjects: "verify email",
      Assignee: doctorimg,
      status: "open",
      createddate: "20/04/2024",
      duedate: "24/04/2024",
      action: {
        view: <FaEye />,
        delete: <MdOutlineDeleteOutline />,
      },
    },
  ]);

  const [deletemodal, setDeletemodal] = useState(false);
  const modalshow = () => setDeletemodal(true);
  const modalclose = () => setDeletemodal(false);

  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="appointments supportaddress">
          <h4 class="h4">Address Grievance</h4>
          <div className="supporttable">
            <Table responsive>
              <thead>
                <tr>
                  {tableheaddata.map((th) => (
                    <th>{th}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tabledata.map((td) => (
                  <tr>
                    <td>{td.sno}</td>
                    <td>{td.id}</td>
                    <td>{td.subjects}</td>
                    <td>
                      <img
                        src={td.Assignee}
                        alt="Assignee"
                        className="tableimg"
                      />
                    </td>
                    <td>
                      <span
                        className={
                          td.status == "open"
                            ? "status bg-green"
                            : "status bg-red"
                        }
                      >
                        {td.status}
                      </span>
                    </td>
                    <td>{td.createddate}</td>
                    <td>{td.duedate}</td>
                    <td>
                      <div className="flexbox">
                        {td.action.view}
                        <button
                          type="button"
                          className="btn"
                          onClick={modalshow}
                        >
                          {td.action.delete}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <DeleteTicketsModal delete={deletemodal} close={modalclose} />
    </div>
  );
};

export default SupportAddressTable;
