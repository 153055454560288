import { React, useState } from "react";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import { FaStar } from "react-icons/fa";
import patient from "../Assets/Images/dashboard/patient.png";

const Appointments = () => {
  const [ratings, setRatings] = useState([
    {
      patientimg: patient,
      reviewername: "Lorem ipsum",
      reviewerdate: "24-Apr-2024",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae molestias, voluptas nemo quos, doloribus delectus ducimus ipsum libero dicta eum quae commodi sint, magni aut earum nihil esse. Quia, cupiditate.",
      descname: "Dean Tolle",
    },
    {
      patientimg: patient,
      reviewername: "Lorem ipsum",
      reviewerdate: "24-Apr-2024",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae molestias, voluptas nemo quos, doloribus delectus ducimus ipsum libero dicta eum quae commodi sint, magni aut earum nihil esse. Quia, cupiditate.",
      descname: "Dean Tolle",
    },
  ]);

  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="reviewratings">
          <div className="appointments appointmentsbox">
            <h4 className="h4">Review Ratings</h4>
            <div className="formbox">
              <div className="greybox">
                <p className="content">Overall Rating</p>
                <div className="flexbox">
                  <div>
                    <h5 className="h5">4.5</h5>
                  </div>
                  <div>
                    <div className="all-ratings">
                      <FaStar className="star-fill" />
                      <FaStar className="star-fill" />
                      <FaStar className="star-fill" />
                      <FaStar className="star-fill" />
                      <FaStar className="star-unfill" />
                    </div>
                  </div>
                </div>
              </div>
              {ratings.map((rt) => (
                <div className="ratingbox">
                  <div className="ratingboxhead">
                    <div className="flexbox">
                      <div>
                        <div className="flex">
                          <div>
                            <img
                              src={rt.patientimg}
                              alt="Rating Image"
                              className="ratingimg"
                            />
                          </div>
                          <div>
                            <span className="reviewername">
                              {rt.reviewername}
                            </span>
                            <small className="reviewerdate">
                              {rt.reviewerdate}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="all-ratings">
                          <FaStar className="star-fill" />
                          <FaStar className="star-fill" />
                          <FaStar className="star-fill" />
                          <FaStar className="star-fill" />
                          <FaStar className="star-unfill" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ratingsboxbody">
                    <p className="content">{rt.desc}</p>
                    <span className="ratingsubname">- {rt.descname}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointments;
