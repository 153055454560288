import React from "react";
import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const AdminModifyDoctor = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        {/* <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content"></div> */}
        <div className="addadmin mt-4">
          <div className="appointments">
            <h5 className="text_gray_lg">Modify Doctor</h5>
            <div className="formbox">
              <Form>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={6} className="mb-4">
                    <Form.Group className="mb-4 pe-lg-5">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4 pe-lg-5">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4 pe-lg-5">
                      <Form.Label>Email Id</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="pe-lg-5">
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6} className="mb-4">
                    <Form.Group className="mb-4 ps-lg-5">
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4 ps-lg-5">
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="ps-lg-5">
                      <Form.Label>Department</Form.Label>
                      <Form.Control as="textarea"></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={12} xl={12} className="mt-5">
                    <Link
                      to="/admin-provider-acces"
                      className="btn btn-details me-3"
                    >
                      Back
                    </Link>
                    <Link
                      to="/admin-provider-access"
                      className="btn btn-details bgdarkgreen"
                    >
                      Update
                    </Link>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminModifyDoctor;
