import { React, useState } from "react";
import adminimg from "../Assets/Images/dashboard/men.png";
import { IoEye } from "react-icons/io5";
import { HiMiniPencilSquare } from "react-icons/hi2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import DeleteList from "./Modal/DeleteList";

// local file import

import noprofile from "../Assets/Images/notprofile.png";

const AdminCard = () => {
  const [admincard, setAdmincard] = useState([
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
    {
      img: noprofile,
      cardname: "Lorem ipsum",
    },
  ]);

  const [deletecard, setDeletecard] = useState("");
  // const deletefunc = () => setDeletecard(true);
  const deletefuncclose = () => setDeletecard(false);

  return (
    <div className="admincard">
      {admincard.map((ac) => (
        <div className="admincardbox">
          <img src={ac.img} alt="Admin Image" className="admincardimg" />
          <span className="admincardname">{ac.cardname}</span>
          <div className="hovericons">
            <div>
              <div className="hicons bg-darkgreen">
                <Link to="/provider">
                  <IoEye />
                </Link>
              </div>
            </div>
            <div>
              <div className="hicons bg-darkgreen">
                <Link to="/edit-admin">
                  <HiMiniPencilSquare />
                </Link>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn"
                onClick={() => setDeletecard("admindelete")}
              >
                <div className="hicons bg-darkgreen">
                  <RiDeleteBin6Line />
                </div>
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn"
                onClick={() => setDeletecard("adminblock")}
              >
                <div className="hicons bg-darkgreen bg-darkred">
                  <MdOutlineDoNotDisturbAlt />
                </div>
              </button>
            </div>
          </div>
        </div>
      ))}
      {/* <DeleteList deletecard={deletecard} deleteclose={deletefuncclose} /> */}
      <DeleteList deletecard={deletecard} deleteclose={deletefuncclose} />
    </div>
  );
};

export default AdminCard;
