import React, { useRef, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

import uploadImage from "../../Assets/Images/upload.svg";
import prescriptionImage from "../../Assets/Images/prescription.svg";
import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";

const AddProvider = () => {
  const uploadRef = useRef(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const handleEducationUpload = () => {
    uploadRef.current.click();
  };
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="addadmin">
          <div className="appointments">
            <h4 className="h4">Add Provider</h4>
            <div className="formbox">
              <Form>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={6} className="mb-4">
                    <Form.Group className="mb-4 pe-lg-5">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4 pe-lg-5">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4 pe-lg-5">
                      <Form.Label>Email Id</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="pe-lg-5">
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6} className="mb-4">
                    <Form.Group className="mb-4 ps-lg-5">
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4 ps-lg-5">
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="ps-lg-5">
                      <Form.Label>Bio</Form.Label>
                      <Form.Control as="textarea">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <div className="mt-5 edu_qual">
                      <div>
                        <Form.Label>Education Details</Form.Label>
                        <button
                          className="uplaod_container d-flex flex-column  align-items-center justify-content-center  mb-4 w-100"
                          onClick={handleEducationUpload}
                        >
                          <img
                            src={uploadImage}
                            alt="upload"
                            className="img-fluid"
                            style={{ width: "20px" }}
                          />
                          <p className="mt-2">
                            Drag & Drop or{" "}
                            <span className="link_span">Choose</span> file here
                          </p>
                          <input
                            type="file"
                            className="d-none"
                            ref={uploadRef}
                          />
                        </button>

                        <div>
                          <div className="upload_prescription mb-3">
                            <div className="row mx-auto">
                              <div className="col-10 ps-0">
                                <div>
                                  <div className="d-flex align-items-center gap-3">
                                    <img
                                      src={prescriptionImage}
                                      alt="prescription"
                                      className="img-fluid"
                                    />
                                    <div>
                                      <p className="fw_450 mb-1">
                                        Doctor prescriptions
                                      </p>
                                      <p className="mb-0 text_black_xxs">
                                        Doctor prescriptions
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                                <button className="border-0 outline-0 bg-transparent">
                                  <IoMdClose fontSize={20} />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="upload_prescription">
                            <div className="row mx-auto">
                              <div className="col-10 ps-0">
                                <div>
                                  <div className="d-flex align-items-center gap-3">
                                    <img
                                      src={prescriptionImage}
                                      alt="prescription"
                                      className="img-fluid"
                                    />
                                    <div>
                                      <p className="fw_450 mb-1">
                                        Doctor prescriptions
                                      </p>
                                      <p className="mb-0 text_black_xxs">
                                        Doctor prescriptions
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                                <button className="border-0 outline-0 bg-transparent">
                                  <IoMdClose fontSize={20} />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={12} xl={12} className="mt-5">
                    <Link
                      to="/superAdmin-providerAccess"
                      className="btn btn-details me-3"
                    >
                      Back
                    </Link>
                    <Link
                      to="/superAdmin-providerAccess"
                      className="btn btn-details bgdarkgreen"
                    >
                      Add Doctor
                    </Link>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProvider;
