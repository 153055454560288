import {React, useState} from 'react';
import { Table } from 'react-bootstrap';
import { FaEye } from "react-icons/fa";
import DeleteList from '../Modal/DeleteList';

const PatientTable = (props) => {

    const tablehead = ["S.No", "Patient Name", "Provider Name", "date", "Care Name", "action"];
    const [patientdata, setPatientdata] = useState([
        {
            sno: "1",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
        {
            sno: "2",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
        {
            sno: "3",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
        {
            sno: "4",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
        {
            sno: "5",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
        {
            sno: "6",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
        {
            sno: "7",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
        {
            sno: "8",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
        {
            sno: "9",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
        {
            sno: "10",
            patientname: "Demo",
            providername: "Online",
            date: "07/04/2024",
            carename: "Demo",
            action: <FaEye />
        },
    ]);

    const [patientdel, setPatientdel] = useState(false);
    const changemodal = () => setPatientdel(true);
    const closemodal = () => setPatientdel(false);

    const providerhead = ["S.No", "Appointment Date", "Provider Name", "Patients Name", "Fees", "Platform Commission",
        "Provider Commission", "Status"
    ];
    const [providercommission, setProvidercommission] = useState([
        {
            sno: "1",
            appointmentdate: "07/04/2024",
            providername: "Lorem ipsum",
            patientname: "Lorem ipsum",
            fees: "$200",
            platformcommission: "$24.01",
            providercomm: "$24.01",
            status: "Pending"
        },
    ]);

    

  return (
    <div className="supporttable">
        <Table>
            <thead>
                <tr>
                    { tablehead.map((th) => (
                        <th>{th}</th>
                    )) }
                </tr>
            </thead>
            <tbody>
                { patientdata.map((td) => (
                    <tr>
                        <td>{td.sno}</td>
                        <td>{td.patientname}</td>
                        <td>{td.providername}</td>
                        <td>{td.date}</td>
                        <td>{td.carename}</td>
                        <td>
                            <button type="button" className="btn" onClick={changemodal}>{td.action}</button>
                        </td>
                    </tr>
                )) }
            </tbody>
        </Table>
        <DeleteList patientdel={patientdel} hide={closemodal} />
    </div>
  )
}

export default PatientTable;