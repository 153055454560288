import { React, useState } from "react";
import { Link } from "react-router-dom";
import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import { FaPlus } from "react-icons/fa6";
import { FiUserPlus } from "react-icons/fi";
import AdminProviderCard from "../component/AdminProviderCard";

const AdminProviderAccess = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="appointments mt-5">
          <div className="flexbox">
            <div>
              <h4 className="h4">Provider</h4>
            </div>
            <div className="d-flex align-items-center gap-3">
              <Link
                className="btn btn-details bgdarkgreen"
                to="/admin-add-doctor"
              >
                <span className="pe-1">
                  <FaPlus />
                </span>{" "}
                Add Provider
              </Link>{" "}
              <Link className="btn btn-details bgdarkgreen">
                <span className="pe-2">
                  <FiUserPlus fontSize={15} className="mb-1" />
                </span>{" "}
                Invite
              </Link>
            </div>
          </div>
          <AdminProviderCard />
        </div>
      </div>
    </div>
  );
};

export default AdminProviderAccess;
