import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// import PatientTable from "./table/PatientTable";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import calendar from "../Assets/Images/calendar.png";
import { Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";

const Earnings = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const summaryhead = ["Cost", "Discount", "Tax", "Invoice"];
  const detailshead = [
    "S.No",
    "Date",
    "Invoice Number",
    "Patients Name",
    "Service",
    "Cost",
    "Provider Commission",
    "Tax",
    "Invoice Amount",
    "Amount",
  ];
  const [summarydata, setSummarydata] = useState([
    {
      cost: "$24.01",
      discount: "$06.01",
      tax: "$02.01",
      invoice: "$24.01",
    },
  ]);
  const [detailsdata, setDetailsdata] = useState([
    {
      sno: "1",
      date: "07/04/2024",
      number: "TTV60142",
      name: "Lorem ipsum",
      service: "Lorem",
      cost: "$24.01",
      provider: "$24.01",
      tax: "$06.01",
      amount: "$06.01",
      amt: "$06.01",
    },
  ]);
  return (
    <>
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />
          <div className="py-2 py-sm-4 dashboard-content">
            <div className="container-fluid earning_page">
              <div className="head_sec">
                <div className="d-flex align-items-center gap-3">
                  <h6 className="mb-0 cat_txt">Category</h6>

                  <Dropdown className="choose_cat_drop">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Income
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Income</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Outcome</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="choose_date">
                  <div className="d-flex align-items-center gap-2">
                    <span className="date_txt">From</span>
                    <div className="customDatePicker">
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        icon={
                          <img
                            src={calendar}
                            className="img-fluid calendar_logo"
                          />
                        }
                        showIcon
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <span className="date_txt">To</span>
                    <div className="customDatePicker to">
                      <DatePicker
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                        icon={
                          <img
                            src={calendar}
                            className="img-fluid calendar_logo"
                          />
                        }
                        showIcon
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="earning_summary mt-4 mt-lg-5">
                <h5 className="heading_txt">Earning Summary</h5>
                <div className="supporttable">
                  <Table responsive>
                    <thead>
                      <tr>
                        {summaryhead.map((th) => (
                          <th>{th}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {summarydata.map((td) => (
                        <tr>
                          <td>{td.cost}</td>
                          <td>{td.discount}</td>
                          <td>{td.tax}</td>
                          <td>{td.invoice}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="earning_summary mt-4 mt-lg-5">
                <h5 className="heading_txt">Earning Details</h5>
                <div className="supporttable">
                  <Table responsive>
                    <thead>
                      <tr>
                        {detailshead.map((th) => (
                          <th>{th}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {detailsdata.map((td) => (
                        <tr>
                          <td>{td.sno}</td>
                          <td>{td.date}</td>
                          <td>{td.number}</td>
                          <td>{td.name}</td>
                          <td>{td.service}</td>
                          <td>{td.cost}</td>
                          <td>{td.provider}</td>
                          <td>{td.tax}</td>
                          <td>{td.amount}</td>
                          <td>{td.amt}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Earnings;
