import React, { useState } from "react";

// icon import

import { FaEyeSlash, FaEye } from "react-icons/fa";

// Files import

import logo from "../Assets/Images/black-logo.png";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const handleSwitchPassword = () => {
    setPasswordShow(!passwordShow);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const [loginAuth, setLoginAuth] = useState({
    email: "",
    password: "",
  });

  const { email, password } = loginAuth;

  const handleLoginChange = (e) => {
    const { value, name } = e.target;
    setLoginAuth((prev) => ({ ...prev, [name]: value }));
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };
  const handleLogin = () => {
    if (email === "admin@gmail.com" && password === "Admin@123") {
      navigate("/admin-dashboard");
      localStorage.setItem("admintype", "admin");
    } else if (
      email === "superadmin@gmail.com" &&
      password === "Superadmin@123"
    ) {
      navigate("/dashboard-superadmin");
      localStorage.setItem("admintype", "superadmin");
    } else {
      return;
    }
  };
  return (
    <div className="admin_creds">
      <div className="container h-100">
        <div className="row justify-content-center align-items-center h-100 ">
          <div className="col-5">
            <div className="auth_cards">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="logo" className="img-fluid" />
              </div>

              <h4 className="auth_title pt-5">Admin Credentials</h4>

              <div className="d-flex flex-column mt-4">
                <label className="ps-0 auth_label">Email Id</label>
                <input
                  type="text"
                  name="email"
                  className="auth_input mt-2 px-2"
                  placeholder="Email Address"
                  onChange={handleLoginChange}
                />
                {/* <p className="auth_error_text mt-2 mb-0">*Email need</p> */}
              </div>

              <div className="d-flex flex-column mt-4">
                <label className="ps-0 auth_label">Password</label>

                <div className="auth_pass_wrap mt-2">
                  <div className="row h-100 mx-auto">
                    <div className="col-10 ps-2">
                      <input
                        type={passwordType}
                        name="password"
                        className="h-100"
                        placeholder="Password"
                        onChange={handleLoginChange}
                      />
                    </div>
                    <div className="col-2 pe-2 d-flex justify-content-end">
                      <button
                        onClick={handleSwitchPassword}
                        className="auth_pass_icons"
                      >
                        {passwordShow ? (
                          <FaEye fontSize={16} />
                        ) : (
                          <FaEyeSlash fontSize={16} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {/* <p className="auth_error_text mt-2 mb-0">*Password need</p> */}
              </div>
              <div className="mt_2rem">
                <button className="pm_button_three w-100" onClick={handleLogin}>
                  Login
                </button>
              </div>

              <div className="mt-3 d-flex justify-content-center ">
                <button className="line_button" onClick={handleForgotPassword}>
                  Forgot password?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
