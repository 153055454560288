import { React, useState } from "react";
import { Link } from "react-router-dom";
import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import { FaPlus } from "react-icons/fa6";
import AdminCard from "../AdminCard";

const Admin = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="admin">
          <div className="appointments">
            <div className="flexbox">
              <div>
                <h4 className="h4">Admin</h4>
              </div>
              <div>
                <Link className="btn btn-details bgdarkgreen" to="/add-admin">
                  <span className="pe-1">
                    <FaPlus />
                  </span>{" "}
                  Add Admin
                </Link>
              </div>
            </div>
            <AdminCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
