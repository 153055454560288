import { React, useState } from "react";
import { Link } from "react-router-dom";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import ProviderCard from "./ProviderCard";
import { FaPlus } from "react-icons/fa6";

const ProviderAccess = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="appointments mt-5">
          <div className="flexbox">
            <div>
              <h4 className="h4">Provider</h4>
            </div>
            <div>
              <Link className="btn btn-details bgdarkgreen" to="/add-provider">
                <span className="pe-1">
                  <FaPlus />
                </span>{" "}
                Add Provider
              </Link>
            </div>
          </div>
          <ProviderCard />
        </div>
      </div>
    </div>
  );
};

export default ProviderAccess;
