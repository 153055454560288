import { React, useState } from "react";
import { Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

// local file import

import patientImage from "../../Assets/Images/dashboard/patient.png";
import DeleteNewAppointment from "../Modal/DeleteNewAppointment";

const SuperAdminAppointTable = () => {
  const tableheaddata = [
    "S.No",
    "Patient Name",
    "Age",
    "Gender",
    "Mobile Number",
    "Department",
    "Payment",
    "Date",
    "Time",
    "Doctor",
    "Fees",
    "Action",
  ];
  const [tabledata, setTabledata] = useState([
    {
      sno: "1",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
    {
      sno: "2",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
    {
      sno: "3",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
    {
      sno: "4",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
    {
      sno: "5",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
    {
      sno: "6",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
    {
      sno: "7",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
    {
      sno: "8",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
    {
      sno: "9",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
    {
      sno: "10",
      patientDetails: {
        img: patientImage,
        name: "Steve",
      },
      age: "22",
      gender: "Female",
      mobileNumber: "9874652310",
      department: "Lorem",
      payment: "Paid",
      date: "24/04/2024",
      time: "11:00 AM",
      doctor: "Lorem",
      fees: "$40/Patients",
    },
  ]);

  const [deleteNewAppointment, setDeleteNewAppointment] = useState(false);
  const handleShowDeleteNewAppointment = () => setDeleteNewAppointment(true);
  const handleCloseDeleteNewAppointment = () => setDeleteNewAppointment(false);

  return (
    <div className="appointments supportaddress">
      <DeleteNewAppointment
        show={deleteNewAppointment}
        handleClose={handleCloseDeleteNewAppointment}
      />
      <div className="supporttable">
        <Table responsive>
          <thead>
            <tr>
              {tableheaddata.map((th) => (
                <th>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tabledata.map((td) => (
              <tr>
                <td>{td.sno}</td>
                <td>
                  <div className="d-flex align-items-center gap-3">
                    <div className="table_img_wrap">
                      <img
                        src={td.patientDetails.img}
                        alt={td.patientDetails.name}
                        className="img_container_fit"
                      />{" "}
                    </div>{" "}
                    {td.patientDetails.name}
                  </div>
                </td>
                <td>{td.age}</td>

                <td>
                  {/* <span
                    className={
                      td.status == "open" ? "status bg-green" : "status bg-red"
                    }
                  >
                    {td.gender}
                  </span> */}
                  {td.gender}
                </td>
                <td>{td.mobileNumber}</td>
                <td>{td.department}</td>
                <td
                  className={
                    td.payment === "Paid"
                      ? "table_green_text"
                      : "table_red_text"
                  }
                >
                  {td.payment}
                </td>
                <td>{td.date}</td>
                <td>{td.time}</td>
                <td>{td.doctor}</td>
                <td>{td.fees}</td>

                <td>
                  <div className="flexbox">
                    <button
                      type="button"
                      className="btn"
                      onClick={handleShowDeleteNewAppointment}
                    >
                      <MdDelete />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default SuperAdminAppointTable;
