import React, { useState, useEffect } from "react";
import Logo from "../../../Assets/Images/dashboard/logo.png";

import dashboard from "../../../Assets/Images/dashboard/sidebar/dashboard.png";
import records from "../../../Assets/Images/dashboard/sidebar/records.png";
import settings from "../../../Assets/Images/dashboard/sidebar/setting.png";
import helpTask from "../../../Assets/Images/dashboard/sidebar/HelpTask.png";
import friends from "../../../Assets/Images/dashboard/sidebar/friends.png";

import dashboardNew from "../../../Assets/Images/dashboard/sidebar/dashboardNew.png";
import recordsNew from "../../../Assets/Images/dashboard/sidebar/recordsNew.png";
import availability from "../../../Assets/Images/dashboard/sidebar/availability.png";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import closeicon from "../../../Assets/Images/dashboard/close-icon.png";
import Dropdown from "react-bootstrap/Dropdown";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

// local file import

import admindashboard from "../../../Assets/Images/admindashboard.svg";
import adminaccess from "../../../Assets/Images/adminaccess.svg";
import provideraccess from "../../../Assets/Images/provideraccess.svg";
import adminlogin from "../../../Assets/Images/adminlogin.svg";
import adminsettings from "../../../Assets/Images/adminsetting.svg";
import adminsupport from "../../../Assets/Images/adminsupport.svg";

function DashSidebar({ onhide }) {
  const location = useLocation();
  const Location = location.pathname;
  const [affiliate, setAffiliate] = useState("");
  const [tradingProfit, setTradingProfit] = useState("");
  const [helpDeak, setHelpDeak] = useState(false);
  const [type, setType] = useState("");
  const handleHelpDesk = () => {
    setHelpDeak(!helpDeak);
  };

  const [adminTypes, setAdminTypes] = useState(null);

  useEffect(() => {
    if (true) {
      setType(localStorage.getItem("type"));
    }
    if (Location == "/raise-grievance" || Location == "/rate-experience") {
      setHelpDeak(true);
    }
  }, [Location]);

  useEffect(() => {
    setAdminTypes(localStorage.getItem("admintype"));
  });

  console.log("admintype", adminTypes);

  return (
    <div className="sidebar_sec">
      <div className="logo_sec">
        <Link
          to={
            adminTypes === "admin"
              ? "/admin-dashboard"
              : "/dashboard-superadmin"
          }
        >
          <img src={Logo} alt="logo" className="img-fluid logo" />
        </Link>

        <img
          src={closeicon}
          alt="close Btn"
          className="img-fluid close_offcanvas_logo"
          onClick={onhide}
        />
      </div>

      {adminTypes == "admin" ? (
        <>
          {/* admin Panel */}
          <div className="sideheader">
            <div className="cmn-margin">
              <Link
                to="/admin-dashboard"
                className={
                  Location == "/admin-dashboard" ||
                  Location == "/admin-total-patients" ||
                  Location == "/admin-new-appointments" ||
                  Location == "/view-provider-applications" ||
                  Location == "/earnings"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={admindashboard} alt="" className="sidebar_logo" />
                  <shandleNewAppointmentNavigatepan>
                    Dashboard
                  </shandleNewAppointmentNavigatepan>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <Link
                to="/admin-provider-access"
                className={
                  Location == "/admin-provider-access" ||
                  Location == "/admin-view-provider" ||
                  Location == "/admin-add-doctor" ||
                  Location == "/admin-modify-doctor"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={provideraccess} alt="" className="sidebar_logo" />
                  <span>Provider Access</span>
                </p>
              </Link>
              {/* <NavLink to="/records">records</NavLink> */}
            </div>
            <div className="cmn-margin">
              <Link
                to="/admin-login-settings"
                className={
                  Location == "/admin-login-settings"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={adminaccess} alt="" className="sidebar_logo" />
                  <span>Admin Login Settings</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <div className="">
                <Link
                  // to="/raise-grievance"
                  className={
                    Location == "/address-grievance" ||
                    Location == "/admin-support" ||
                    Location == "/review-ratings" ||
                    helpDeak == true
                      ? "navi-link cmn-hover active DashSide_customDrop"
                      : "navi-link cmn-hover inactive DashSide_customDrop"
                  }
                  onClick={() => handleHelpDesk()}
                >
                  <p className="mb-0 title-txt">
                    <img src={adminsupport} alt="" className="sidebar_logo" />
                    <span>Support</span>
                  </p>

                  <Button
                    className="arrow-btn icon active"
                    // onClick={() => handleHelpDesk()}
                  >
                    {helpDeak ? <IoIosArrowDown /> : <IoIosArrowForward />}
                  </Button>
                </Link>
              </div>
              {helpDeak ? (
                <>
                  <div className="sub-headings mt-2">
                    <Link
                      to="/address-grievance"
                      className={
                        Location == "/address-grievance"
                          ? "mb-2 active"
                          : "mb-2"
                      }
                    >
                      Address Grievance
                    </Link>
                    <Link
                      to="/review-ratings"
                      className={
                        Location == "/review-ratings" ? "active" : "mb-2"
                      }
                    >
                      Review Ratings
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {/* super admin Panel */}
          <div className="sideheader">
            <div className="cmn-margin">
              <Link
                to="/dashboard-superadmin"
                className={
                  Location == "/dashboard-superadmin" ||
                  Location == "/superadmin-total-patients" ||
                  Location == "/superadmin-new-appointments"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={admindashboard} alt="" className="sidebar_logo" />
                  <span>Dashboard</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <Link
                to="/admin-access"
                className={
                  Location == "/admin-access" ||
                  Location == "/provider" ||
                  Location == "/add-admin"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={adminaccess} alt="" className="sidebar_logo" />
                  <span>Admin Access</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <Link
                to="/superAdmin-providerAccess"
                className={
                  Location == "/superAdmin-providerAccess" ||
                  Location == "/superAdmin-viewProvider" ||
                  Location == "/superAdmin-viewProvider" ||
                  Location == "/superadmin-modify-doctor"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={provideraccess} alt="" className="sidebar_logo" />
                  <span>Provider Access</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <Link
                to="/admin-login-settings"
                className={
                  Location == "/admin-login-settings"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={adminlogin} alt="" className="sidebar_logo" />
                  <span>Admin Login Settings</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <div className="">
                <Link
                  // to="/raise-grievance"
                  className={
                    Location == "/payment-integration" ||
                    Location == "/provider-commission" ||
                    helpDeak == true
                      ? "navi-link cmn-hover active DashSide_customDrop"
                      : "navi-link cmn-hover inactive DashSide_customDrop"
                  }
                  onClick={() => handleHelpDesk()}
                >
                  <p className="mb-0 title-txt">
                    <img src={adminsettings} alt="" className="sidebar_logo" />
                    <span>Platform Settings</span>
                  </p>

                  <Button
                    className="arrow-btn icon active px-0"
                    // onClick={() => handleHelpDesk()}
                  >
                    {helpDeak ? <IoIosArrowDown /> : <IoIosArrowForward />}
                  </Button>
                </Link>
              </div>
              {helpDeak ? (
                <>
                  <div className="sub-headings mt-2">
                    <Link
                      to="/payment-integration"
                      className={
                        Location == "/payment-integration"
                          ? "mb-2 active"
                          : "mb-2"
                      }
                    >
                      Payment Integration
                    </Link>
                    <Link
                      to="/provider-commission"
                      className={
                        Location == "/provider-commission" ? "active" : "mb-2"
                      }
                    >
                      Provider Commission
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DashSidebar;
