import { React, useState } from "react";

import TotalPatientTable from "../table/TotalPatientTable";
import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";

const TotalPatients = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />

        <div className="cust_pads">
          <TotalPatientTable />
        </div>
      </div>
    </div>
  );
};

export default TotalPatients;
