import React, { useRef } from "react";
import "./App.css";
import "./custom.css";
import "./aligner.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import "react-calendar/dist/Calendar.css";
import { Route, Routes } from "react-router-dom";
import AddressGrievance from "./AdminPanel/table/SupportAddressTable";
import ReviewRatings from "./AdminPanel/ReviewRatings";
import DashboardSuperAdmin from "./SuperAdmin/DashboardSuperAdmin";
import Admin from "./SuperAdmin/Pages/Admin";
import AdminCard from "./SuperAdmin/AdminCard";
import Provider from "./SuperAdmin/Provider";
import AddAdmin from "./SuperAdmin/AddAdmin";
import PaymentIntegration from "./SuperAdmin/PaymentIntegration";
import ProviderCommission from "./SuperAdmin/ProviderCommission";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import AdminDashboard from "./pages/AdminDashboard";
import ProviderAccess from "./SuperAdmin/ProviderAccess";
import ViewProvider from "./SuperAdmin/ViewProvider";
// import Earnings from "./SuperAdmin/Pages/Earnings";
import TotalPatients from "./AdminPanel/Pages/TotalPatients";
import PatientNewAppointment from "./AdminPanel/Pages/PatientNewAppointment";
import AdminAddDoctor from "./AdminPanel/Pages/AdminAddDoctor";
import AdminModifyDoctor from "./AdminPanel/Pages/AdminModifyDoctor";
import AdminLoginSettings from "./pages/AdminLoginSettings";
import SuperAdminTotalPatients from "./SuperAdmin/Pages/SuperAdminTotalPatient";
import SuperAdminNewAppointment from "./SuperAdmin/Pages/SuperAdminNewAppointments";
import Earnings from "./pages/Earnings";
import ModifyAdmin from "./SuperAdmin/ModifyAdmin";
import AddProvider from "./SuperAdmin/Pages/AddProvider";
import ModifyDoctor from "./SuperAdmin/Pages/ModifyDoctor";
import AdminProviderAccess from "./AdminPanel/Pages/AdminProviderAccess";
import AdminViewProvider from "./AdminPanel/Pages/AdminViewProvider";
import ViewProviderApplication from "./AdminPanel/Pages/ViewProviderApplication";
function App() {
  return (
    <div className="App">
      <Routes>
        {/* start of common routes */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/admin-login-settings"
          element={<AdminLoginSettings />}
        ></Route>
        {/* end of common routes */}
        <Route path="/admin-card" element={<AdminCard />}></Route>
        <Route path="/provider" element={<Provider />}></Route>
        <Route
          path="/payment-integration"
          element={<PaymentIntegration />}
        ></Route>
        <Route
          path="/provider-commission"
          element={<ProviderCommission />}
        ></Route>
        {/* start of admin routes */}
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/admin-total-patients" element={<TotalPatients />} />
        <Route
          path="/admin-new-appointments"
          element={<PatientNewAppointment />}
        />
        <Route
          path="/admin-provider-access"
          element={<AdminProviderAccess />}
        />{" "}
        <Route path="/admin-view-provider" element={<AdminViewProvider />} />
        <Route
          path="/view-provider-applications"
          element={<ViewProviderApplication />}
        />
        <Route path="/earnings" element={<Earnings />} />
        <Route path="/admin-add-doctor" element={<AdminAddDoctor />} />
        <Route path="/admin-modify-doctor" element={<AdminModifyDoctor />} />
        <Route path="/address-grievance" element={<AddressGrievance />}></Route>
        <Route path="/review-ratings" element={<ReviewRatings />}></Route>
        {/* end of admin routes */}
        {/* start of super admin routes */}
        <Route
          path="/dashboard-superadmin"
          element={<DashboardSuperAdmin />}
        />{" "}
        <Route
          path="/superadmin-total-patients"
          element={<SuperAdminTotalPatients />}
        />{" "}
        <Route
          path="/superadmin-new-appointments"
          element={<SuperAdminNewAppointment />}
        />
        <Route path="/admin-access" element={<Admin />} />
        <Route path="/add-admin" element={<AddAdmin />} />{" "}
        <Route path="/edit-admin" element={<ModifyAdmin />} />
        <Route path="/superAdmin-providerAccess" element={<ProviderAccess />} />
        <Route path="/superAdmin-viewProvider" element={<ViewProvider />} />
        <Route path="/add-provider" element={<AddProvider />} />
        <Route path="/superadmin-modify-doctor" element={<ModifyDoctor />} />
        <Route path="/payment-integration" element={<PaymentIntegration />} />
        <Route path="/provider-commission" element={<ProviderCommission />} />
        {/* end of super admin routes */}
      </Routes>
    </div>
  );
}

export default App;
