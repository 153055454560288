import React from "react";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const AddAdmin = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="addadmin">
          <div className="appointments">
            <h4 className="h4">Add Admin</h4>
            <div className="formbox">
              <Form>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={6} className="mb-4">
                    <Form.Group className="mb-4 pe-lg-5">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4 pe-lg-5">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4 pe-lg-5">
                      <Form.Label>Email Id</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="pe-lg-5">
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6} className="mb-4">
                    <Form.Group className="mb-4 ps-lg-5">
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4 ps-lg-5">
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Demo"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="ps-lg-5">
                      <Form.Label>Bio</Form.Label>
                      <Form.Control as="textarea">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={12} xl={12}>
                    <Link to="/admin" className="btn btn-details me-3">
                      Back
                    </Link>
                    <Link
                      to="/admin-access"
                      className="btn btn-details bgdarkgreen"
                    >
                      Add admin
                    </Link>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
