import React from 'react';
import DashSidebar from '../UserPanel/Pages/DashboardPages/DashSidebar';
import DashHeader from '../UserPanel/Pages/DashboardPages/DashHeader';
import wix from "../Assets/Images/wix.png";
import stripe from "../Assets/Images/stripe.png";
import { Link } from 'react-router-dom';

const PaymentIntegration = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="paymentintegration">
            <div className="appointments">
                <h4 className="h4">Payment Integration</h4>
                <div className="formbox">   
                    <Link to="/">
                        <div className="paymentbox">
                            <div className="flexbox">
                                <div>
                                    <p className="content">
                                        WIX Payment Settings
                                    </p>
                                </div>
                                <div>
                                    <img src={wix} alt="Wix" className="paymentimg" />
                                </div>
                            </div>
                        </div>
                    </Link>    
                    <Link to="/">
                        <div className="paymentbox">
                            <div className="flexbox">
                                <div>
                                    <p className="content">
                                        Stripe Payment Settings
                                    </p>
                                </div>
                                <div>
                                    <img src={stripe} alt="Stripe" className="paymentimg" />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
      </div>
    </div> 
  )
}

export default PaymentIntegration;;