import React from 'react';
import DashSidebar from '../UserPanel/Pages/DashboardPages/DashSidebar';
import DashHeader from '../UserPanel/Pages/DashboardPages/DashHeader';
import PatientTable from './table/PatientTable';

const ProviderCommission = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="paymentintegration">
            <div className="appointments">
                <h4 className="h4">Provider Commission</h4>
                <PatientTable />
            </div>
        </div>
      </div>
    </div>
  )
}

export default ProviderCommission;