import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import { FiPlusCircle } from "react-icons/fi";
import { Row, Col } from "react-bootstrap";
import { FiPhone } from "react-icons/fi";
import { CiMail } from "react-icons/ci";
import Time from "../Assets/Images/time.png";
import report from "../Assets/Images/report.png";
import pdf from "../Assets/Images/pdf.png";
import { FiDownload } from "react-icons/fi";
import ViewReport from "./Modal/ViewReport";
import Check from "../Assets/Images/dashboard/check.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profile from "../Assets/Images/doctor1.png";
import rating from "../Assets/Images/star_rating.png";
import stepbar from "../Assets/Images/stepBar_icon.png";

const ViewProvider = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("overview");

  var time = Time;

  const [weekdays, setWeekdays] = useState([
    {
      timeicon: time,
      day: "Monday",
    },
    {
      timeicon: time,
      day: "tuesday",
    },
    {
      timeicon: time,
      day: "wednesday",
    },
    {
      timeicon: time,
      day: "thursday",
    },
    {
      timeicon: time,
      day: "friday",
    },
    {
      timeicon: time,
      day: "saturday",
    },
    {
      timeicon: time,
      day: "sunday",
    },
  ]);

  const [dytimes, setDytimes] = useState([
    {
      timeicon: time,
      times: "08:00 - 02:00",
    },
    {
      timeicon: time,
      times: "08:00 - 02:00",
    },
    {
      timeicon: time,
      times: "08:00 - 02:00",
    },
    {
      timeicon: time,
      times: "08:00 - 02:00",
    },
    {
      timeicon: time,
      times: "08:00 - 02:00",
    },
    {
      timeicon: time,
      times: "08:00 - 02:00",
    },
    {
      timeicon: time,
      times: "08:00 - 02:00",
    },
  ]);

  const [show, setShow] = useState(false);
  const docmodal = () => setShow(true);
  const handleClose = () => setShow(false);

  const [pdfs, setPdfs] = useState([
    {
      pdfdoc: report,
      pdfname: "Medical Report",
    },
    {
      pdfdoc: report,
      pdfname: "Medical Report",
    },
    {
      pdfdoc: report,
      pdfname: "Medical Report",
    },
    {
      pdfdoc: report,
      pdfname: "Medical Report",
    },
  ]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const reviews = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
  ];

  const proExp = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
  ];

  const handleAddProvider = () => {
    navigate("/add-provider");
  };
  return (
    <>
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />
          <div className="px-2 py-2 px-sm-2 py-sm-4 dashboard-content">
            {/* <DashHome /> */}

            <div className="superAdmin__wholeProvider">
              <h5 className="m-0 superAdmin__title">Provider</h5>

              <div className="superAdmin__shadowBox rounded-2 mt-3">
                <div className="superAdmin__limeBox rounded-2 p-2 text-end">
                  <button
                    className="superAdmin__greenBtn rounded-2"
                    onClick={handleAddProvider}
                  >
                    <FiPlusCircle className="me-2" /> Add Provider
                  </button>
                </div>
                <div className="superAdmin__providerDtlsHolder position-relative">
                  <div className="superAdmin__providerDtls d-flex flex-column align-items-center p-3 position-absolute">
                    <img
                      src={require("../Assets/Images/dashboard/doctor-two.png")}
                      alt="doctoImg"
                      className="img-fluid superAdmin__providerImg rounded-5"
                    />

                    <p className="m-0 superAdmin__providerName text-center mt-2">
                      Dr.Calvin Carlo
                    </p>
                    <p className="text-center m-0 providerCard_caretype">
                      Eye Care
                    </p>
                  </div>
                </div>

                <div className="provider_customTabs superAdmin__taberTable mx-3 rounded-2">
                  <div className="superAdmin__topTaber d-flex">
                    <button
                      className={`superAdmin__taberGrnBtn p-2 flex-grow-1 rounded-2 ${
                        activeTab == "overview" && "active"
                      }`}
                      onClick={() => setActiveTab("overview")}
                    >
                      OverView
                    </button>
                    <button
                      className={`superAdmin__taberGrnBtn p-2 flex-grow-1 rounded-2 ${
                        activeTab == "experience" && "active"
                      }`}
                      onClick={() => setActiveTab("experience")}
                    >
                      Experience
                    </button>
                    <button
                      className={`superAdmin__taberGrnBtn p-2 flex-grow-1 rounded-2 ${
                        activeTab == "reviews" && "active"
                      }`}
                      onClick={() => setActiveTab("reviews")}
                    >
                      Reviews
                    </button>
                    <button
                      className={`superAdmin__taberGrnBtn p-2 flex-grow-1 rounded-2 ${
                        activeTab == "timeTable" && "active"
                      }`}
                      onClick={() => setActiveTab("timeTable")}
                    >
                      Time Table
                    </button>
                    <button
                      className={`superAdmin__taberGrnBtn p-2 flex-grow-1 rounded-2 ${
                        activeTab == "education" && "active"
                      }`}
                      onClick={() => setActiveTab("education")}
                    >
                      Education Details
                    </button>
                  </div>
                  <div className="superAdmin__bodyTaber">
                    {activeTab == "overview" ? (
                      <>
                        <div className="overview">
                          <p className="content mb-4">
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Pariatur iusto sunt fuga ratione, tenetur at
                            et ex ipsum. Dolores praesentium reiciendis quos
                            quae. Ipsam reprehenderit accusantium, neque fugit
                            quis repudiandae? Lorem ipsum dolor sit, amet
                            consectetur adipisicing elit. Pariatur iusto sunt
                            fuga ratione, tenetur at et ex ipsum. Dolores
                            praesentium reiciendis quos quae. Ipsam
                            reprehenderit accusantium, neque fugit quis
                            repudiandae?
                          </p>
                          <h6 className="providersubhead">Specialties :</h6>
                          <ul className="specialties_list mt-3">
                            <li className="mb-3">
                              <img src={Check} />
                              <span className="ms-2">Heart Specalist</span>
                            </li>
                            <li className="mb-3">
                              <img src={Check} />
                              <span className="ms-2">Heart Specalist</span>
                            </li>
                            <li className="mb-3">
                              <img src={Check} />
                              <span className="ms-2">Heart Specalist</span>
                            </li>
                            <li className="mb-3">
                              <img src={Check} />
                              <span className="ms-2">Heart Specalist</span>
                            </li>
                            <li className="mb-0">
                              <img src={Check} />
                              <span className="ms-2">Heart Specalist</span>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : activeTab == "experience" ? (
                      <>
                        <div className="experience">
                          <h6 className="providersubhead">Experience :</h6>
                          <p className="content mb-4">
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                          </p>
                          <h6 className="providersubhead">
                            Professional Experience :
                          </h6>
                          <div className="prof_exp">
                            {proExp.map((item, index) => {
                              return (
                                <div className="cus_card">
                                  {/* <div className="stepbar_logo">
                              <img src={stepbar} className="img-fluid" />
                            </div> */}

                                  <div className="exp_card text-center">
                                    <p className="year mb-0">2010 - 2016</p>
                                    <p className="role my-2">
                                      Master of medicine
                                    </p>
                                    <p className="hospital mb-0">
                                      ( Loerm Hospital )
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : activeTab == "reviews" ? (
                      <>
                        <div className="reviews">
                          <div className="reviews_carousal">
                            <Slider {...settings}>
                              {reviews.map((item, index) => {
                                return (
                                  <div className="row d-flex align-items-center justify-content-center">
                                    <div className="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-8 col-xxl-7">
                                      <div className="user_reviews text-center">
                                        <img
                                          src={profile}
                                          alt="profile"
                                          className="img-fluid user_profile"
                                        />
                                        <p className="name my-2">
                                          - Dean Tolle
                                        </p>
                                        <p className="review_txt mb-0">
                                          Lorem ipsum dolor sit amet, consetetur
                                          sadipscing elitr, sed diam nonumy
                                          eirmod tempor invidunt ut labore et
                                          dolore magna aliquyam erat, sed diam
                                          voluptua. At vero eos et accusam et
                                          justo duo dolores et ea rebum. Stet
                                          clita kasd gubergren, no sea takimata
                                          sanctus est Lorem ipsum dolor sit
                                          amet.
                                        </p>
                                        <div className="ratings mt-4">
                                          <img
                                            src={rating}
                                            className="img-fluid"
                                          />
                                          <img
                                            src={rating}
                                            className="img-fluid"
                                          />
                                          <img
                                            src={rating}
                                            className="img-fluid"
                                          />
                                          <img
                                            src={rating}
                                            className="img-fluid"
                                          />
                                          <img
                                            src={rating}
                                            className="img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                        </div>
                      </>
                    ) : activeTab == "timeTable" ? (
                      <>
                        <Row className="justify-content-between align-items-center">
                          <Col
                            xs={12}
                            md={12}
                            lg={6}
                            xl={6}
                            xxl={6}
                            className="mb-4 mb-lg-0"
                          >
                            <div className="flexbox mb-5">
                              <div>
                                <div className="cntbox">
                                  <FiPhone />
                                </div>
                              </div>
                              <div>
                                <span className="cntname">Phone</span>
                                <p className="content">
                                  Lorem ipsum dolor sit amet, consetetur
                                  sadipscing elitr, sed diam nonumy eirmod
                                  tempor invidunt ut labore et dolore magna
                                  aliquyam erat.
                                </p>
                                <span className="cntmobile">
                                  +021-8562478875
                                </span>
                              </div>
                            </div>
                            <div className="flexbox">
                              <div>
                                <div className="cntbox">
                                  <CiMail />
                                </div>
                              </div>
                              <div>
                                <span className="cntname">Email</span>
                                <p className="content">
                                  Lorem ipsum dolor sit amet, consetetur
                                  sadipscing elitr, sed diam nonumy eirmod
                                  tempor invidunt ut labore et dolore magna
                                  aliquyam erat.
                                </p>
                                <span className="cntmobile">
                                  demo@gmail.com
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} md={12} lg={6} xl={6} xxl={5}>
                            <div className="greybox">
                              <div className="flexbox">
                                <div>
                                  <ul className="dayslist">
                                    {weekdays.map((d) => (
                                      <li>
                                        <img src={d.timeicon} alt="" />{" "}
                                        <span>{d.day}</span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div>
                                  <ul className="dayslist">
                                    {dytimes.map((t) => (
                                      <li>
                                        <img src={t.timeicon} alt="" /> Time:{" "}
                                        <span className="t-green">
                                          {t.times}
                                        </span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <div>
                          <p>Education Details :</p>
                          <div className="pdffiles">
                            {pdfs.map((e) => (
                              <div className="pdffilesbox">
                                <img src={e.pdfdoc} alt="" className="docimg" />
                                <div className="flexbox mt-4">
                                  <div>
                                    <img src={pdf} alt="" className="docicon" />
                                    <span className="content ps-2">
                                      {e.pdfname}
                                    </span>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={docmodal}
                                    >
                                      <FiDownload />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewReport show={show} handleClose={handleClose} />
    </>
  );
};

export default ViewProvider;
