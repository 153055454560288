import { React, useState } from "react";
import { IoEye } from "react-icons/io5";
import { HiMiniPencilSquare } from "react-icons/hi2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import Doctor1 from "../../Assets/Images/landing/doctor5.png";
import Doctor2 from "../../Assets/Images/landing/doctor6.png";
import Doctor3 from "../../Assets/Images/landing/doctor7.png";
import Doctor4 from "../../Assets/Images/landing/doctor8.png";
import Doctor5 from "../../Assets/Images/landing/doctor9.png";
import Doctor6 from "../../Assets/Images/landing/doctor10.png";
import AdminBlockProvider from "../Modal/AdminBlockProvider";
import AdminDeleteProvider from "../Modal/AdminDeleteProvider";

const AdminProviderCard = () => {
  const [admincard, setAdmincard] = useState([
    {
      img: Doctor1,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
    {
      img: Doctor2,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
    {
      img: Doctor5,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
    {
      img: Doctor3,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
    {
      img: Doctor4,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
    {
      img: Doctor5,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
    {
      img: Doctor6,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
    {
      img: Doctor1,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
    {
      img: Doctor2,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
    {
      img: Doctor3,
      cardname: "Lorem ipsum",
      care: "Eye Care",
    },
  ]);

  const [showDeleteProvider, setShowDeleteProvider] = useState(false);
  const [showBlockProvider, setShowBlockProvider] = useState(false);

  const handleShowDeleteProvider = () => {
    setShowDeleteProvider(true);
  };
  const handleCloseDeleteProvider = () => {
    setShowDeleteProvider(false);
  };
  const handleShowBlockProvider = () => {
    setShowBlockProvider(true);
  };
  const handleCloseBlockProvider = () => {
    setShowBlockProvider(false);
  };

  return (
    <>
      {/* start of modal import */}

      <AdminBlockProvider
        show={showDeleteProvider}
        handleClose={handleCloseDeleteProvider}
      />
      <AdminDeleteProvider
        show={showBlockProvider}
        handleClose={handleCloseBlockProvider}
      />

      {/* end of modal import */}
      <div className="admincard">
        {admincard.map((ac) => (
          <div className="admincardbox providerCardBox p-2">
            <img
              src={ac.img}
              alt="Admin Image"
              className="admincardimg providerCardImg"
            />
            <span className="admincardname">{ac.cardname}</span>
            <p className="text-center m-0 providerCard_caretype">{ac.care}</p>
            <div className="hovericons">
              <div>
                <div className="hicons bg-darkgreen">
                  <Link to="/admin-view-provider">
                    <IoEye />
                  </Link>
                </div>
              </div>
              <div>
                <div className="hicons bg-darkgreen">
                  <Link to="/admin-modify-doctor">
                    <HiMiniPencilSquare />
                  </Link>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="btn"
                  onClick={handleShowDeleteProvider}
                >
                  <div className="hicons bg-darkgreen">
                    <RiDeleteBin6Line />
                  </div>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn"
                  onClick={handleShowBlockProvider}
                >
                  <div className="hicons bg-darkgreen bg-darkred">
                    <MdOutlineDoNotDisturbAlt />
                  </div>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AdminProviderCard;
