import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../../../Assets/Images/dashboard/logo.png";
import profile from "../../../Assets/Images/dashboard/profile.png";
// import mobileLogo from "../../Assets/Images/mobile-logo.png";
import { FiShare2 } from "react-icons/fi";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaArrowLeft } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import search from "../../../Assets/Images/dashboard/search.png";
import DashSidebar from "./DashSidebar";
function DashHeader() {
  const location = useLocation();
  const navigate = useNavigate();

  const [getAdminType, setGetAdminType] = useState(null);

  const Location = location.pathname;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSignOut = () => {
    localStorage.removeItem("type");
    navigate("/login");
  };

  useEffect(() => {
    setGetAdminType(localStorage.getItem("admintype"));
  });
  return (
    <>
      <div className="dashboard_header">
        <Navbar expand="xl" className="d-block d-flex">
          <Container
            fluid
            className="position-relative justify-content-between px-1 px-lg-2 custom-dashnav gap-2"
          >
            <Navbar.Brand>
              <Link to="/dashboard" className="mbl-logo">
                <img src={logo} alt="logo" className="img-fluid" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="navbar_tog"
            >
              <span className="custom_hamburger">
                <GiHamburgerMenu />
              </span>
            </Navbar.Toggle>

            <Navbar.Collapse id="basic-navbar-nav" className="mob_vieww">
              <div className="container-fluid px-0 px-lg-2">
                <Nav className="d-flex gap-2 justify-content-between align-items-start align-items-xl-center navbar_sec">
                  <div className="dash-header-search">
                    <input
                      type="text"
                      className=""
                      placeholder="Search pathology results"
                    />
                    <img src={search} className="search-logo" />
                  </div>
                  <div className="right-sec">
                    <Dropdown className="profile-drop">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="profile_info"
                      >
                        <img src={profile} className="img-fluid profile-logo" />
                        <div className="d-flex align-items-start flex-column">
                          <span className="name f-15 fw-600">Lorem Ipsum</span>
                          {getAdminType === "admin" ? (
                            <span className="type f-13 fw-400">Admin</span>
                          ) : (
                            <span className="type f-13 fw-400">
                              Super Admin
                            </span>
                          )}
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item className="f-15" onClick={handleSignOut}>
                          Sign Out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Nav>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Button
          variant="primary"
          onClick={handleShow}
          className="cmn-greenBtn offcan-btn"
        >
          <FaArrowLeft />
        </Button>
        <div className="headerNav-offcanvas">
          <Offcanvas
            show={show}
            onHide={handleClose}
            className="offcanvas_mbl_sidebar dashboard_sec headerNav-offcanvas"
          >
            <Offcanvas.Body>
              {/* <DashSidebar onhide={handleClose} /> */}
              <DashSidebar onhide={handleClose} />
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </>
  );
}

export default DashHeader;
