import { React, useState } from "react";

// import local files

import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";

import SuperAdminAppointTable from "../table/SuperAdminAppointmentTable";

const SuperAdminNewAppointment = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />

        <div className="cust_pads">
          <SuperAdminAppointTable />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminNewAppointment;
