import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

// local file import
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import doctor1 from "../Assets/Images/doctor1.png";
import doctor2 from "../Assets/Images/doctor2.png";
import doctor3 from "../Assets/Images/doctor3.png";
import doctor4 from "../Assets/Images/doctor4.png";
import doctor5 from "../Assets/Images/doctor5.png";
import doctor6 from "../Assets/Images/doctor6.png";
import doctor7 from "../Assets/Images/doctor7.png";
import doctor8 from "../Assets/Images/doctor8.png";
import doctor9 from "../Assets/Images/doctor9.png";
import doctor10 from "../Assets/Images/doctor10.png";
import cancelImage from "../Assets/Images/cancelred.png";
import orangeHold from "../Assets/Images/orangeonhold.png";
import whitetick from "../Assets/Images/whitetick.svg";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [provierApplications, setProviderApplications] = useState([
    {
      id: 1,
      image: doctor1,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
    {
      id: 2,
      image: doctor2,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
    {
      id: 3,
      image: doctor3,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
    {
      id: 4,
      image: doctor4,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
    {
      id: 5,
      image: doctor5,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
    {
      id: 6,
      image: doctor6,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
    {
      id: 1,
      image: doctor7,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
    {
      id: 1,
      image: doctor8,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
    {
      id: 1,
      image: doctor9,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
    {
      id: 1,
      image: doctor10,
      name: "Dr. Calvin Carlo",
      role: "Eye Care",
    },
  ]);

  const handleTotalPatientNavigate = () => {
    navigate("/admin-total-patients");
  };
  const handleNewAppointmentNavigate = () => {
    navigate("/admin-new-appointments");
  };
  const handleAdminProviderAccessNavigate = () => {
    navigate("/admin-provider-access");
  };
  const handleEarningNavigate = () => {
    navigate("/earnings");
  };
  const handleApproveProvider = () => {
    navigate("/view-provider-applications");
  };
  return (
    <>
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />
          <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
            {/* <DashHome /> */}
          </div>
          <div className="superadmindashboard">
            <div className="appointments">
              <Row>
                <Col lg={3} md={4} sm={6} className="mb-4">
                  <div
                    className="dash__singleBox d-flex flex-column justify-content-between overflow-hidden rounded-4"
                    style={{ cursor: "pointer" }}
                    onClick={handleTotalPatientNavigate}
                  >
                    <div className="d-flex justify-content-between align-items-start px-3 pt-4">
                      <img
                        src={
                          require("../Assets/Images/dashboard/dashImage/totalpatients.svg")
                            .default
                        }
                        className="img-fluid dash__singleBoxLogos rounded-3"
                      />
                      <div className="dash__singleBoxCountHolder">
                        <p className="m-0 dash__singleBoxLabel text-end">
                          Total Patients
                        </p>
                        <h6 className="m-0 dash__singleBoxValue text-end mt-1">
                          0
                        </h6>
                      </div>
                    </div>
                    <img
                      src={
                        require("../Assets/Images/dashboard/dashImage/bluegraph.svg")
                          .default
                      }
                      className="img-fluid  dash__singleBoxGraph mt-3"
                    />
                  </div>
                </Col>

                <Col lg={3} md={4} sm={6} className="mb-4">
                  <div
                    className="dash__singleBox d-flex flex-column justify-content-between overflow-hidden rounded-4 greenline"
                    style={{ cursor: "pointer" }}
                    onClick={handleNewAppointmentNavigate}
                  >
                    <div className="d-flex justify-content-between align-items-start px-3 pt-4">
                      <img
                        src={
                          require("../Assets/Images/dashboard/dashImage/calendar.svg")
                            .default
                        }
                        className="img-fluid dash__singleBoxLogos rounded-3"
                      />
                      <div className="dash__singleBoxCountHolder">
                        <p className="m-0 dash__singleBoxLabel text-end">
                          New Appointment
                        </p>
                        <h6 className="m-0 dash__singleBoxValue text-end mt-1">
                          0
                        </h6>
                      </div>
                    </div>
                    <img
                      src={
                        require("../Assets/Images/dashboard/dashImage/greenGraph.svg")
                          .default
                      }
                      className="img-fluid  dash__singleBoxGraph mt-3"
                    />
                  </div>
                </Col>

                <Col lg={3} md={4} sm={6} className="mb-4">
                  <div
                    className="dash__singleBox d-flex flex-column justify-content-between overflow-hidden rounded-4 orangeline"
                    style={{ cursor: "pointer" }}
                    onClick={handleAdminProviderAccessNavigate}
                  >
                    <div className="d-flex justify-content-between align-items-start px-3 pt-4">
                      <img
                        src={
                          require("../Assets/Images/dashboard/dashImage/doctor.svg")
                            .default
                        }
                        className="img-fluid dash__singleBoxLogos rounded-3"
                      />
                      <div className="dash__singleBoxCountHolder">
                        <p className="m-0 dash__singleBoxLabel text-end">
                          Total Doctor
                        </p>
                        <h6 className="m-0 dash__singleBoxValue text-end mt-1">
                          0
                        </h6>
                      </div>
                    </div>
                    <img
                      src={
                        require("../Assets/Images/dashboard/dashImage/orangegraph.svg")
                          .default
                      }
                      className="img-fluid  dash__singleBoxGraph mt-3"
                    />
                  </div>
                </Col>

                <Col lg={3} md={4} sm={6} className="mb-4">
                  <div
                    className="dash__singleBox d-flex flex-column justify-content-between overflow-hidden rounded-4 violetline"
                    style={{ cursor: "pointer" }}
                    onClick={handleEarningNavigate}
                  >
                    <div className="d-flex justify-content-between align-items-start px-3 pt-4">
                      <img
                        src={
                          require("../Assets/Images/dashboard/dashImage/earnings.svg")
                            .default
                        }
                        className="img-fluid dash__singleBoxLogos rounded-3"
                      />
                      <div className="dash__singleBoxCountHolder">
                        <p className="m-0 dash__singleBoxLabel text-end">
                          Earnings
                        </p>
                        <h6 className="m-0 dash__singleBoxValue text-end mt-1">
                          0
                        </h6>
                      </div>
                    </div>
                    <img
                      src={
                        require("../Assets/Images/dashboard/dashImage/vioGraph.svg")
                          .default
                      }
                      className="img-fluid  dash__singleBoxGraph mt-3"
                    />
                  </div>
                </Col>
              </Row>
              {/* <PatientTable /> */}

              {/* <PatientTable /> */}
            </div>
            <div className="cust_pads">
              <h5 className="text_gray_lg">Provider Applications</h5>
              <div className="row mt-4 mt-lg-5">
                {provierApplications.map((item, index) => (
                  <div
                    key={index}
                    className="col-12 col-md-6 col-lg-4 col-xxxl-3 col-xxxxl-2_5  mb-4  d-flex justify-content-center justify-content-md-start"
                  >
                    <div className="prov_app_card ">
                      <div
                        className="prov_img_wrap"
                        onClick={handleApproveProvider}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={item.image}
                          alt="doctor"
                          className="img-fluid img_container_fit"
                        />
                      </div>

                      <p className="text-center text_dark_md mt-3 mb-0">
                        {" "}
                        {item.name}
                      </p>
                      <p className="text-center text_gray_sm mt-2 mb-0">
                        {item.role}
                      </p>

                      <div className="prov_btn_wrap">
                        <div className="mt-3 d-flex align-items-center justify-content-between gap-3">
                          <button className="d-flex align-items-center justify-content-center gap-3 pm_red_btn">
                            {" "}
                            <img
                              src={cancelImage}
                              alt="cancel"
                              className="img-fluid"
                              style={{ width: "10px" }}
                            />
                            Reject
                          </button>
                          <button className="d-flex align-items-center justify-content-center gap-2 pm_orange_btn">
                            {" "}
                            <img
                              src={orangeHold}
                              alt="hold"
                              className="img-fluid"
                              style={{ width: "14px" }}
                            />
                            On Hold
                          </button>
                        </div>

                        <div>
                          <button className="pm_green_btn d-flex align-items-center justify-content-center gap-2 w-100 mt-3 mb-2">
                            <img
                              src={whitetick}
                              alt="tick"
                              className="img-fluid"
                            />
                            Approve
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
